<template>
	<div>
		<div class="container">
			<div class="handle-box">
				<el-select size="small" v-if="isAdmin" @change="agentChange" v-model="query.agentId" filterable
					clearable placeholder="代理商" class="handle-select mr10" style="width: 300px">
					<el-option v-for="item in agents" :key="item.value" :label="item.label"
						:value="item.value"></el-option>
				</el-select>
				<el-select size="small" v-if="isNormal" v-model="query.companyId" @change="companyChange" filterable
					clearable placeholder="企业" class="handle-select mr10" style="width: 300px">
					<el-option v-for="item in companys" :key="item.value" :label="item.label"
						:value="item.value"></el-option>
				</el-select>
				<el-input size="small" v-model="query.phoneNumber" clearable placeholder="请输入黑名单号码"
					class="handle-input mr10" style="width: 180px"></el-input>
				<el-button size="small" type="primary" icon="el-icon-search" @click="handleSearch"
					class="mt10">搜索</el-button>
				<el-button size="small" type="primary" @click="showForm" class="mt10">添加黑名单</el-button>
				<el-button size="small" type="primary" icon="el-icon-delete" @click="batchDel"
					class="mt10">批量删除</el-button>
				<el-button size="small" type="primary" @click="showForm2" class="mt10">导入文件</el-button>
				<el-button size="small" type="primary" @click="handleDownload" v-if="isAdmin||isAgent||isCompany"
					class="mt10">导出黑名单</el-button>
			</div>

			<el-table :data="tableData" border class="table" ref="multipleTable" header-cell-class-name="table-header"
				@selection-change="handleSelectionChange" :header-cell-style="{ 'text-align': 'center' }"
				:row-style="{ height: '20px' }" :cell-style="{ padding: '6px' }" :row-class-name="tableRowClassName">
				<el-table-column type="selection" width="55" align="center"></el-table-column>
				<el-table-column prop="companyName" width="340" label="公司" align="center"></el-table-column>
				<el-table-column prop="phoneNumber" label="黑名单" align="center"></el-table-column>
				<!-- 	<el-table-column prop="companyName" width="340" label="代理商"></el-table-column>
				<el-table-column prop="departmentName" label="部门"></el-table-column>-->

				<el-table-column prop="userPhone" label="员工" align="center"></el-table-column>
				<el-table-column prop="createdAt" label="创建时间" align="center"></el-table-column>
				<el-table-column prop="remark" label="备注" align="center"></el-table-column>
				<el-table-column label="操作" width="180" align="center">
					<template #default="scope">
						<el-button size="small" type="text" icon="el-icon-delete" class="red"
							@click="handleDelete(scope.$index, scope.row.id)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div class="pagination">
				<el-pagination background layout="total, sizes, prev, pager, next, jumper"
					:current-page="query.pageIndex" :page-sizes="[30, 50, 100, 500]" :page-size="query.pageSize"
					:total="pageTotal" @size-change="handleSizeChange"
					@current-change="handlePageChange"></el-pagination>
			</div>

			<el-dialog title="添加黑名单" v-model="addVisible" width="500px" @close="closeDialog('form')"
				:close-on-click-modal="false">
				<el-form :model="form" :rules="rules" ref="form" label-width="100px">
					<el-form-item label="代理" prop="agentId" v-if="isAdmin">
						<el-select size="small" @change="agentChange" v-model="query.agentId" filterable clearable
							placeholder="代理商" class="handle-select mr10" style="width: 100%">
							<el-option v-for="item in agents" :key="item.value" :label="item.label"
								:value="item.value"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="企业" prop="companyId" v-if="isNormal">
						<el-select size="small" v-model="query.companyId" @change="companyChange" filterable clearable
							placeholder="企业" class="handle-select mr10" style="width: 100%">
							<el-option v-for="item in companys" :key="item.value" :label="item.label"
								:value="item.value"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="黑名单号码" prop="phoneNumber">
						<el-input size="small" type="textarea" :rows="5" placeholder="一行一个黑名单号码,一次最多添加100个号码"
							v-model="phoneNumber"></el-input>
					</el-form-item>

					<el-form-item label="原因" prop="remark">
						<el-radio-group v-model="query.remark" @change="blackRadioChange"
							style="border: 1px solid #EBEEF5;padding: 8px;">
							<el-radio style="width: 100%;margin-bottom: 10px;"
								label="明确表示不再允许打电话">明确表示不再允许打电话</el-radio>
							<el-radio style="width: 100%;margin-bottom: 10px;" label="有明显投诉倾向">有明显投诉倾向</el-radio>
							<el-radio style="width: 100%;margin-bottom: 10px;" label="已确认非目标客户">已确认非目标客户</el-radio>
							<el-radio style="width: 100%;" label="其他">其他</el-radio>
						</el-radio-group>
					</el-form-item>
				</el-form>
				<span class="dialog-footer" style="display: flex;flex-direction: row;justify-content: flex-end;">
					<el-button size="small" @click="closeDialog('form')">取消</el-button>
					<el-button size="small" type="primary" @click="bulkAdd('form')" :loading="loading">确定</el-button>
				</span>
			</el-dialog>
			<el-dialog title="导入黑名单" v-model="ImportVisible" width="500px" @close="closeDialog('form')"
				:close-on-click-modal="false">
				<el-form :model="form" :rules="rules" ref="form" label-width="100px" class="import-visible">
					<el-form-item label="代理" prop="agentId" v-if="isAdmin">
						<el-select size="small" @change="agentChange" v-model="query.agentId" filterable clearable
							placeholder="代理商" class="handle-select mr10" style="width: 100%">
							<el-option v-for="item in agents" :key="item.value" :label="item.label"
								:value="item.value"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="企业" prop="companyId" v-if="isNormal">
						<el-select size="small" v-model="query.companyId" @change="companyChange" filterable clearable
							placeholder="企业" class="handle-select mr10" style="width: 100%">
							<el-option v-for="item in companys" :key="item.value" :label="item.label"
								:value="item.value"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="上传文件" prop="">
						<el-upload ref="upload" action="/api/blackList/upload" :headers="token" :limit="1"
							:on-success="uploadSuccess" :on-preview="handlePreview" :on-remove="handleRemove"
							:auto-upload="false" :data="query">
							<el-button size="small" type="primary">选择文件</el-button>
							<div class="el-upload__tip">只能导入excel文件</div>
						</el-upload>
					</el-form-item>
					<el-form-item label="原因" prop="remark">
						<el-radio-group v-model="query.remark" @change="blackRadioChange"
							style="border: 1px solid #EBEEF5;padding: 8px;">
							<el-radio style="width: 100%;margin-bottom: 10px;"
								label="明确表示不再允许打电话">明确表示不再允许打电话</el-radio>
							<el-radio style="width: 100%;margin-bottom: 10px;" label="有明显投诉倾向">有明显投诉倾向</el-radio>
							<el-radio style="width: 100%;margin-bottom: 10px;" label="已确认非目标客户">已确认非目标客户</el-radio>
							<el-radio style="width: 100%;" label="其他">其他</el-radio>
						</el-radio-group>
					</el-form-item>
				</el-form>
				<span class="dialog-footer" style="display: flex;flex-direction: row;justify-content: flex-end;">
					<el-button size="small" @click="closeDialog('form')">取消</el-button>
					<el-button size="small" type="primary" @click="submitUpload" :loading="loading">确定</el-button>
				</span>
			</el-dialog>
		</div>
	</div>
</template>

<script>
	import { fetchUserById, getBlackList, blackListDelete, getbulkAdd, downloadBlackList } from '../api/userIndex';
	import { fetchDepartment } from '../api/index.js';
	import { fetchAgent, fetchInitAgent } from '../api/agentIndex.js';
	import { fetchCompanyByAgentId, fetchDefaultCompanies } from '../api/companyIndex';
	export default {
		name: 'user',
		data() {
			return {
				query: {
					companyId: '',
					agentId: '',
					phoneNumber: '',
					pageIndex: 1,
					pageSize: 30,
					remark: '其他'
				},
				agents: [],
				companys: [],
				departments: [],
				tableData: [],
				multipleSelection: [],
				delList: [],
				agentDis: false,
				isAdmin: false,
				isAgent: false,
				isNormal: false,
				isCompany: false,
				companyDis: false,
				pageTotal: 0,
				loading: false,
				invalidateDate: 0,
				role: '',
				addVisible: false,
				ImportVisible: false,
				blackremark: '',
				phoneNumber: '',
				form: {}
			};
		},
		created() {
			this.role = localStorage.getItem('ms_role');
			this.init();
			if (this.role === 'admin') {
				this.isAdmin = true;
				this.agentDis = true;
				this.companyDis = true;
				this.isNormal = true;
			}
			if (this.role === 'agent') {
				this.isAgent = true;
				this.companyDis = true;
				this.isNormal = true;
			} else {
				this.getData();
			}
			if (this.role === 'company') {
				this.isCompany = true;
			}
		},
		methods: {
			tableRowClassName({ rowIndex }) {
				if ((rowIndex + 1) % 2 === 0) {
					return 'oddRow';
				}
				return 'evenRow';
			},
			init() {
				let data = {
					currentUserId: localStorage.getItem('user')
				};
				fetchInitAgent(data).then(res => {
					if (res.data) {
						this.invalidateDate = res.data.validCallTime;
					}
				});
				fetchAgent(localStorage.getItem('user')).then(res => {
					this.agents = res.data;
				});

				fetchDefaultCompanies(data).then(res => {
					this.companys = res.data;
					if (this.role == 'agent') {
						this.query.companyId = res.data[0].value;
						this.getData();
					}
				});
			},
			getData() {
				let data = {
					userId: localStorage.getItem('user'),
					agentId: this.query.agentId,
					companyId: this.query.companyId,
					phoneNumber: this.query.phoneNumber.replaceAll(' ', ''),
					pageIndex: this.query.pageIndex,
					pageSize: this.query.pageSize,
					means: 'web'
				};
				getBlackList(data).then(res => {
					if (res.code === 200) {
						this.tableData = res.data.list;
						this.pageTotal = res.data.total;
					} else {
						this.$message.error(res.message);
					}
				});
			},
			agentChange(agentId) {
				this.query.agentId = agentId;
				this.query.companyId = '';
				this.query.departmentId = '';
				this.query.userId = '';
				this.form.agentId = agentId;
				this.form.companyId = '';
				this.form.departmentId = '';
				this.form.userId = '';
				if (agentId) {
					let data = {
						agentId: agentId
					};
					fetchCompanyByAgentId(data).then(res => {
						this.companys = res.data;
					});
				}
			},
			companyChange(companyId) {
				this.query.companyId = companyId;
				this.query.departmentId = '';
				this.query.userId = '';
				this.form.companyId = companyId;
				this.form.departmentId = '';
				this.form.userId = '';
				let data = {
					companyId: companyId,
					currentUserId: localStorage.getItem('user')
				};
				fetchDepartment(data).then(res => {
					this.departments = res.data;
					this.query.pageIndex = 1;
					this.getData();
				});
			},
			//添加
			showForm() {
				this.addVisible = true;
				this.blackremark = this.query.remark;
				this.phoneNumber = '';
			},
			// 触发搜索按钮
			handleSearch() {
				this.query.pageIndex = 1;
				this.getData();
			},
			//黑名单选项
			blackRadioChange(evt) {
				console.log(evt, 'sssssw');
				this.blackremark = evt;
			},

			// 多选操作
			handleSelectionChange(val) {
				this.multipleSelection = val;
			},
			/* 批量删除 */
			batchDel() {
				let str = '';
				this.delList = this.delList.concat(this.multipleSelection);
				for (let i = 0; i < this.multipleSelection.length; i++) {
					str += this.multipleSelection[i].id + ',';
				}
				if (str === '') {
					this.$message.error(`请选择要删除的数据`);
					return;
				} else {
					let param = {
						id: str
					};
					// 二次确认删除
					this.$confirm('确定要删除吗？', '提示', {
							type: 'warning'
						})
						.then(() => {
							blackListDelete(param).then(res => {
								if (res.code == 200) {
									this.$message.success('批量删除成功');
									this.getData();
								} else {
									this.$message.error(res.message);
								}
							});
						})
						.catch(() => {});
					this.multipleSelection = [];
				}
			},
			// 删除操作
			handleDelete(index, id) {
				// 二次确认删除
				this.$confirm('确定要删除吗？', '提示', {
						type: 'warning'
					})
					.then(() => {
						let data = {
							id: id
						};
						this.loading = true;
						blackListDelete(data).then(res => {
							if (res.code == 200) {
								this.$message.success('删除成功');
								this.pageTotal = this.pageTotal - 1;
								this.tableData.splice(index, 1);
								this.loading = false;
								this.getByCustomerFileIds(this.query2.customerFileId);
							} else {
								this.$message.error(res.message);
								this.loading = false;
							}
						});
					})
					.catch(() => {});
			},
			bulkAdd() {
				let data = {
					userId: localStorage.getItem('user'),
					agentId: this.query.agentId,
					companyId: this.query.companyId,
					phoneNumber: this.phoneNumber,
					remark: this.blackremark
				};
				this.loading = true;

				getbulkAdd(data).then(res => {
					if (res.code == 200) {
						this.$message.success(res.message);
						this.addVisible = false;
						this.loading = false;

						this.getData();
					} else {
						this.$message.error(res.message);
						this.loading = false;
					}
				});
			},
			// 分页导航
			handlePageChange(val) {
				this.query.pageIndex = val;
				this.getData();
			},
			handleSizeChange(val) {
				this.query.pageSize = val;
				this.getData();
			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
			},
			closeDialog(formName) {
				this.addVisible = false;
				this.ImportVisible = false;
				(this.form = {
					companyId: '',
					name: '',
					ownerName: '',
					ownerPhone: ''
				}),
				this.resetForm(formName);
			},
			showForm2() {
				this.ImportVisible = true;
				this.blackremark = this.query.remark;
				this.phoneNumber = '';
			},
			submitUpload() {
				this.query.userId = localStorage.getItem('user');
				if (this.$refs.upload.uploadFiles[0]) {
					this.loading = true;
					this.$refs.upload.submit();
				} else {
					this.$message.error('请选择上传文件');
				}
			},
			//导入上传
			uploadSuccess(success) {
				setTimeout(() => {
					this.loading = false;
					this.$refs.upload.clearFiles();
					this.ImportVisible = false;
				}, 2000);
				if (success.code === 200) {
					this.$message.success('上传成功');
					this.getData();
				} else {
					// this.open(success.message);
					this.$message.error(success.message);
				}
			},
			handleDownload() {
				let data = {
					companyId: this.query.companyId,
					userId: localStorage.getItem('user')
				};
				downloadBlackList(data).then(res => {
					console.log(res, 'ssss');
					const blob = new Blob([res], {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
					});
					let downloadElement = document.createElement('a');
					let href = window.URL.createObjectURL(blob);
					downloadElement.href = href;
					let fileName = '黑名单' + '.xlsx';
					downloadElement.download = fileName;
					document.body.appendChild(downloadElement);
					downloadElement.click();
					document.body.removeChild(downloadElement);
					window.URL.revokeObjectURL(href);
				});
			},
		}
	};
</script>

<style scoped>
	.handle-box {
		margin-bottom: 20px;
	}

	.down {
		border: 1px solid #dcdfe6;
		background: #4f7afd;
		color: #ffffff;
		transition: 0.1s;
		font-weight: 500;
		padding: 8px 16px;
		font-size: 14px;
		border-radius: 4px;
		margin-left: 10px;
		display: inline-block;
		width: 88px;
	}

	.handle-select {
		width: 120px;
	}

	.handle-input {
		width: 300px;
		display: inline-block;
	}

	.table {
		width: 100%;
		font-size: 14px;
	}

	.red {
		color: #ff0000;
	}

	.mr10 {
		margin-right: 10px;
	}

	.grid-con-icon {
		font-size: 50px;
		width: 100px;
		height: 100px;
		text-align: center;
		line-height: 100px;
		color: #fff;
	}

	.grid-cont-right {
		flex: 1;
		text-align: center;
		font-size: 20px;
		font-weight: bold;
		color: #999;
	}

	.grid-content {
		display: flex;
		align-items: center;
		height: 100px;
	}
</style>
<style>
	.import-visible .el-upload {
		width: 100% !important;
		display: flex !important;
		flex-direction: column !important;
		align-items: center !important;
		justify-content: center !important;
	}
</style>