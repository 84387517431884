import request from '../utils/request';
import qs from "qs";
//蜗牛充值支付
export const woniuPay = param => {
	return request.post('/api/topUp/pay', param, {
		headers: {
			'Content-Type': 'application/json'
		}
	})
};

//导出
export const download = param => {
	return request.post('/api/topUp/download', qs.stringify(param), {
		responseType: 'blob'
	})
};
//海航用户信息查询
export const queryTelephone = param => {
	return request.post('/api/hh/queryTelephone', qs.stringify(param))
};
//海航用户信息批量查询
export const batchQueryRecord = param => {
	return request.post('/api/hh/batchQueryRecord', qs.stringify(param))
};
//海航停复机记录
export const stopOrOpenRecord = param => {
	return request.post('/api/hh/stopOrOpenRecord', qs.stringify(param))
};

//海航添加停复机
export const stopOrOpen = param => {
	return request.post('/api/hh/stopOrOpen', qs.stringify(param))
};


//海航限制激活记录
export const activateRecord = param => {
	return request.post('/api/hh/activateRecord', qs.stringify(param))
};
//海航添加限制激活
export const getactivate = param => {
	return request.post('/api/hh/activate', qs.stringify(param))
};

//海航添加文件限制激活
export const getbatchActivate = param => {
	return request.post('/api/hh/batchActivate', qs.stringify(param))
};


//海航激活记录下载
export const downloadActivateRecord = param => {
	return request.post('/api/hh/downloadActivateRecord', qs.stringify(param), {
		responseType: 'blob'
	})
};


//海航停复机下载
export const downloadStopOrOpenRecord = param => {
	return request.post('/api/hh/downloadStopOrOpenRecord', qs.stringify(param), {
		responseType: 'blob'
	})
};

//海航资金池添加金额
export const fundPoolAdd = param => {
	return request.post('/api/hh/fundPoolAdd', qs.stringify(param))
};

//海航资金池记录
export const fundPool = param => {
	return request.post('/api/hh/fundPool', qs.stringify(param))
};
//海航取消停机保号
export const cancelProtection = param => {
	return request.post('/api/hh/cancelProtection', qs.stringify(param))
};
//海航取消停机保号列表
export const cancelProtectionList = param => {
	return request.post('/api/hh/cancelProtectionList', qs.stringify(param))
};

//海航单个充值列表
export const topUpRecord = param => {
	return request.post('/api/hh/topUpRecord', qs.stringify(param))
};
//海航单个充值列表下载
export const topUpRecordDownload = param => {
	return request.post('/api/hh/topUpRecordDownload', qs.stringify(param))
};
//海航添加单个充值
export const AddtopUp = param => {
	return request.post('/api/hh/topUp', qs.stringify(param))
};
//海航批量充值列表
export const batchTopUpRecord = param => {
	return request.post('/api/hh/batchTopUpRecord', qs.stringify(param))
};
//海航APP充值开启
export const stopDxtApp = param => {
	return request.post('/api/topUp/stopDxtApp', qs.stringify(param))
};

//查询是否开启app海航充值
export const dxtEnabled = param => {
	return request.post('/api/topUp/dxtEnabled', qs.stringify(param))
};

//海航话单异常列表
export const HHErrRecord = param => {
	return request.post('/api/hh/errRecord', qs.stringify(param))
};

//海航查询设置
export const HHSettings = userId => {
	return request.get('/api/hh/settings?userId=' + userId)
};

//海航查询设置修改
export const updateSettings = param => {
	return request.post('/api/hh/updateSettings', qs.stringify(param))
};

//海航导出
export const downloadExportStop = param => {
	return request.post('/api/hh/ExportStop', qs.stringify(param), {
		responseType: 'blob'
	})
};




//风险黑名单  列表
export const riskCallList = param => {
	return request.post('/api/blackList/riskCallList', qs.stringify(param))
};
//风险黑名单 添加
export const riskCall = param => {
	return request.post('/api/blackList/riskCall', qs.stringify(param))
};
//风险黑名单删除
export const riskCallDelete = param => {
	return request.post('/api/blackList/riskCallDelete', qs.stringify(param))
};