<template>
	<div class="sidebar">
		<div class="logo-img">
			<div class="logo">
				<img class="img" src="../assets/img/henyuan.png" alt="" />
				<span v-if="!collapse"
					style="font-size: 28px;color: #000;font-weight: 600;font-family: cursive;">恒远通</span>
			</div>
		</div>
		<el-menu class="sidebar-el-menu" :default-active="onRoutes" :collapse="collapse" background-color="#ffffff"
			text-color="#000" active-text-color="#ffffff" unique-opened router v-if="role == 'admin'">
			<el-menu-item index="dashboard">
				<i class="el-icon-lx-home"></i>
				<span>系统首页</span>
			</el-menu-item>

			<el-submenu index="customer">
				<template v-slot:title="title">
					<i class="el-icon-user"></i>
					<span>CRM管理</span>
				</template>
				<el-menu-item-group>
					<el-menu-item index="customer">CRM管理</el-menu-item>
					<el-menu-item index="customTag">字段配置</el-menu-item>
					<el-menu-item index="followProgress">跟进阶段配置</el-menu-item>
					<el-menu-item index="source">来源配置</el-menu-item>
					<el-menu-item index="release">释放管理</el-menu-item>
					<el-menu-item index="tag">标签管理</el-menu-item>
				</el-menu-item-group>
			</el-submenu>

			<el-submenu index="clue">
				<template v-slot:title="title">
					<i class="el-icon-data-line"></i>
					<span>线索管理</span>
				</template>
				<el-menu-item-group>
					<el-menu-item index="clue">线索管理</el-menu-item>
					<el-menu-item index="assignLeads">线索回收分配</el-menu-item>
				</el-menu-item-group>
			</el-submenu>
			<el-submenu index="company">
				<template v-slot:title="title">
					<i class="el-icon-lx-cascades"></i>
					<span>企业管理</span>
				</template>
				<el-menu-item-group>
					<el-menu-item index="company">企业管理</el-menu-item>
					<el-menu-item index="department">部门管理</el-menu-item>
					<el-menu-item index="user">员工管理</el-menu-item>
				</el-menu-item-group>
			</el-submenu>
			<el-submenu index="userInfo">
				<template v-slot:title="title">
					<i class="el-icon-s-promotion"></i>
					<span
						:class="[onRoutes == 'userInfo' ? theme3 : '', onRoutes == 'restartRecordList' ? theme3 : '', onRoutes == 'HHAbnormalCallList' ? theme3 : '']">
						海航管理
					</span>
				</template>
				<el-menu-item-group>
					<el-menu-item index="userInfo" :class="onRoutes == 'userInfo' ? theme6 : ''">用户查询</el-menu-item>
					<el-menu-item index="restartRecordList" v-if="interior==='true'"
						:class="onRoutes == 'restartRecordList' ? theme6 : ''">停复机</el-menu-item>
					<el-menu-item index="HHAbnormalCallList"
						:class="onRoutes == 'HHAbnormalCallList' ? theme6 : ''">海航异常话单</el-menu-item>
				</el-menu-item-group>
			</el-submenu>
			<el-submenu index="record">
				<template v-slot:title="title">
					<i class="el-icon-phone-outline"></i>
					<span>话务管理</span>
				</template>
				<el-menu-item-group><el-menu-item index="record">话务管理</el-menu-item></el-menu-item-group>
			</el-submenu>
			<el-submenu index="report">
				<template v-slot:title="title">
					<i class="el-icon-document-copy"></i>
					<span>统计管理</span>
				</template>
				<el-menu-item-group>
					<el-menu-item index="report">员工统计报表</el-menu-item>
					<el-menu-item index="companyStatistics">公司统计报表</el-menu-item>
				</el-menu-item-group>
			</el-submenu>

			<el-submenu index="reportNumber">
				<template v-slot:title="title">
					<i class="el-icon-phone"></i>
					<span>号码分类</span>
				</template>
				<el-menu-item-group>
					<el-menu-item index="reportNumber">报备绿名单号码</el-menu-item>
					<el-menu-item index="hemaBill">河马话单</el-menu-item>
					<el-menu-item index="hanghaiBill">海航话单</el-menu-item>
					<el-menu-item index="blackNameList">风险黑名单</el-menu-item>
					<el-menu-item index="todayBill">今日话单</el-menu-item>
					<el-menu-item index="YTBill">远特话单</el-menu-item>
					<el-menu-item index="shutdownRecord">停机记录</el-menu-item>
				</el-menu-item-group>
			</el-submenu>

			<el-menu-item index="termOfValidity">
				<i class="el-icon-time"></i>
				<span>有效期管理</span>
			</el-menu-item>
			<el-menu-item index="blackList">
				<i class="el-icon-s-release"></i>
				<span>黑名单</span>
			</el-menu-item>
			<el-submenu index="qualityInspection">
				<template v-slot:title="title">
					<i class="el-icon-microphone" :class="[
							onRoutes == 'riskList' ? theme3 : '',
							onRoutes == 'AIinspection' ? theme3 : '',
							onRoutes == 'inspectionRecords' ? theme3 : '',
							onRoutes == 'qualityInspection' ? theme3 : ''
						]"></i>
					<span :class="[
							onRoutes == 'riskList' ? theme3 : '',
							onRoutes == 'AIinspection' ? theme3 : '',
							onRoutes == 'inspectionRecords' ? theme3 : '',
							onRoutes == 'qualityInspection' ? theme3 : ''
						]">
						语音质检
					</span>
				</template>
				<el-menu-item-group>
					<el-menu-item index="qualityInspection"
						:class="onRoutes == 'qualityInspection' ? theme6 : ''">录音质检</el-menu-item>
					<el-menu-item index="riskList" :class="onRoutes == 'riskList' ? theme6 : ''">风险通话列表</el-menu-item>
					<el-menu-item index="AIinspection"
						:class="onRoutes == 'AIinspection' ? theme6 : ''">AI质检</el-menu-item>
					<el-menu-item index="inspectionRecords"
						:class="onRoutes == 'inspectionRecords' ? theme6 : ''">质检记录</el-menu-item>
				</el-menu-item-group>
			</el-submenu>
			<el-submenu index="agent">
				<template v-slot:title="title">
					<i class="el-icon-set-up"></i>
					<span>管理员权限</span>
				</template>
				<el-menu-item-group>
					<el-menu-item index="agent">代理商管理</el-menu-item>
					<el-menu-item index="agentSerial">代理商分配记录</el-menu-item>
					<el-menu-item index="operationLog">员工操作日志</el-menu-item>
					<el-menu-item index="seating">坐席数据</el-menu-item>
					<el-menu-item index="outboundCallRecords">外呼记录</el-menu-item>
				</el-menu-item-group>
			</el-submenu>
			<el-submenu index="cardType">
				<template v-slot:title="title">
					<i class="el-icon-s-tools"></i>
					<span>系统设置</span>
				</template>
				<el-menu-item-group>
					<el-menu-item index="cardType">品牌管理</el-menu-item>
					<el-menu-item index="blackListDatabase">黑名单库</el-menu-item>
					<el-menu-item index="role">角色管理</el-menu-item>
					<el-menu-item index="userSerial">员工分配记录</el-menu-item>
				</el-menu-item-group>
			</el-submenu>

			<el-submenu index="notice">
				<template v-slot:title="title">
					<i class="el-icon-chat-dot-round"></i>
					<span>公告管理</span>
				</template>
				<el-menu-item-group>
					<el-menu-item index="notice">公告列表</el-menu-item>
					<el-menu-item index="addNotice">发布公告</el-menu-item>
					<el-menu-item index="swipes">轮播图列表</el-menu-item>
					<el-menu-item index="OEMswiper">登录轮播图</el-menu-item>
				</el-menu-item-group>
			</el-submenu>
			<el-menu-item index="afterSales">
				<i class="el-icon-warning-outline"></i>
				<span>售后信息</span>
			</el-menu-item>
			<el-menu-item index="operationManual">
				<i class="el-icon-notebook-1"></i>
				<span>使用手册</span>
			</el-menu-item>
			<div class="login_info" v-if="collapse == false">
				<div>最后登录时间:{{ lastLoginAt }}</div>
			</div>
		</el-menu>
		<el-menu class="sidebar-el-menu" :default-active="onRoutes" :collapse="collapse" background-color="#ffffff"
			text-color="#000" active-text-color="#ffffff" unique-opened router v-else-if="role == 'agent'">
			<el-menu-item index="dashboard">
				<i class="el-icon-lx-home"></i>
				<span>系统首页</span>
			</el-menu-item>
			<el-submenu index="customer">
				<template v-slot:title="title">
					<i class="el-icon-user"></i>
					<span>CRM管理</span>
				</template>
				<el-menu-item-group>
					<el-menu-item index="customer">CRM管理</el-menu-item>
					<el-menu-item index="customTag">字段配置</el-menu-item>
					<el-menu-item index="followProgress">跟进阶段配置</el-menu-item>
					<el-menu-item index="source">来源配置</el-menu-item>
					<el-menu-item index="release">释放管理</el-menu-item>
					<el-menu-item index="tag">标签管理</el-menu-item>
				</el-menu-item-group>
			</el-submenu>
			<el-submenu index="clue">
				<template v-slot:title="title">
					<i class="el-icon-data-line"></i>
					<span>线索管理</span>
				</template>
				<el-menu-item-group>
					<el-menu-item index="clue">线索管理</el-menu-item>
					<el-menu-item index="assignLeads">线索回收分配</el-menu-item>
				</el-menu-item-group>
			</el-submenu>
			<el-submenu index="company">
				<template v-slot:title="title">
					<i class="el-icon-lx-cascades"></i>
					<span>企业管理</span>
				</template>
				<el-menu-item-group>
					<el-menu-item index="company">企业管理</el-menu-item>
					<el-menu-item index="department">部门管理</el-menu-item>
					<el-menu-item index="user">员工管理</el-menu-item>
				</el-menu-item-group>
			</el-submenu>
			<el-submenu index="userInfo">
				<template v-slot:title="title">
					<i class="el-icon-s-promotion"></i>
					<span
						:class="[onRoutes == 'userInfo' ? theme3 : '', onRoutes == 'HHAbnormalCallList' ? theme3 : '', onRoutes == 'restartRecordList' ? theme3 : '']">
						海航管理
					</span>
				</template>
				<el-menu-item-group>
					<el-menu-item index="userInfo" :class="onRoutes == 'userInfo' ? theme6 : ''">用户查询</el-menu-item>
					<el-menu-item index="restartRecordList" v-if="interior==='true'"
						:class="onRoutes == 'restartRecordList' ? theme6 : ''">停复机</el-menu-item>
					<el-menu-item index="HHAbnormalCallList"
						:class="onRoutes == 'HHAbnormalCallList' ? theme6 : ''">海航异常话单</el-menu-item>
				</el-menu-item-group>
			</el-submenu>
			<el-submenu index="record">
				<template v-slot:title="title">
					<i class="el-icon-phone-outline"></i>
					<span>话务管理</span>
				</template>
				<el-menu-item-group><el-menu-item index="record">话务管理</el-menu-item></el-menu-item-group>
			</el-submenu>
			<el-submenu index="report">
				<template v-slot:title="title">
					<i class="el-icon-document-copy"></i>
					<span>统计管理</span>
				</template>
				<el-menu-item-group>
					<el-menu-item index="report">员工统计报表</el-menu-item>
					<el-menu-item index="companyStatistics">公司统计报表</el-menu-item>
				</el-menu-item-group>
			</el-submenu>

			<el-submenu index="cardType">
				<template v-slot:title="title">
					<i class="el-icon-s-tools"></i>
					<span>系统设置</span>
				</template>
				<el-menu-item-group>
					<el-menu-item index="cardType">品牌管理</el-menu-item>
					<el-menu-item index="blackListDatabase">黑名单库</el-menu-item>
					<el-menu-item index="userSerial">员工分配记录</el-menu-item>
					<el-menu-item index="role">角色管理</el-menu-item>
					<el-menu-item index="outboundCallRecords">外呼记录</el-menu-item>
				</el-menu-item-group>
			</el-submenu>

			<el-menu-item index="swipes">
				<i class="el-icon-chat-dot-round"></i>
				<span>轮播图列表</span>
			</el-menu-item>
			<el-menu-item index="blackList">
				<i class="el-icon-s-release"></i>
				<span>黑名单</span>
			</el-menu-item>
			<el-submenu index="qualityInspection">
				<template v-slot:title="title">
					<i class="el-icon-microphone" :class="[
							onRoutes == 'riskList' ? theme3 : '',
							onRoutes == 'AIinspection' ? theme3 : '',
							onRoutes == 'inspectionRecords' ? theme3 : '',
							onRoutes == 'qualityInspection' ? theme3 : ''
						]"></i>
					<span :class="[
							onRoutes == 'riskList' ? theme3 : '',
							onRoutes == 'AIinspection' ? theme3 : '',
							onRoutes == 'inspectionRecords' ? theme3 : '',
							onRoutes == 'qualityInspection' ? theme3 : ''
						]">
						语音质检
					</span>
				</template>
				<el-menu-item-group>
					<el-menu-item index="qualityInspection"
						:class="onRoutes == 'qualityInspection' ? theme6 : ''">录音质检</el-menu-item>
					<el-menu-item index="riskList" :class="onRoutes == 'riskList' ? theme6 : ''">风险通话列表</el-menu-item>
					<el-menu-item index="AIinspection"
						:class="onRoutes == 'AIinspection' ? theme6 : ''">AI质检</el-menu-item>
					<el-menu-item index="inspectionRecords"
						:class="onRoutes == 'inspectionRecords' ? theme6 : ''">质检记录</el-menu-item>
				</el-menu-item-group>
			</el-submenu>
			<el-menu-item index="afterSales">
				<i class="el-icon-warning-outline"></i>
				<span>售后信息</span>
			</el-menu-item>

			<el-menu-item index="operationManual">
				<i class="el-icon-notebook-1"></i>
				<span>使用手册</span>
			</el-menu-item>
			<div class="login_info" v-if="collapse == false">
				<div>最后登录时间:{{ lastLoginAt }}</div>
			</div>
		</el-menu>

		<el-menu class="sidebar-el-menu" :default-active="onRoutes" :collapse="collapse" background-color="#ffffff"
			text-color="#000" active-text-color="#ffffff" unique-opened router v-else-if="role == 'company'">
			<el-menu-item index="dashboard">
				<i class="el-icon-lx-home"></i>
				<span>系统首页</span>
			</el-menu-item>
			<el-submenu index="customer">
				<template v-slot:title="title">
					<i class="el-icon-user"></i>
					<span>CRM管理</span>
				</template>
				<el-menu-item-group>
					<el-menu-item index="customer">CRM管理</el-menu-item>
					<el-menu-item index="customTag">字段配置</el-menu-item>
					<el-menu-item index="followProgress">跟进阶段配置</el-menu-item>
					<el-menu-item index="source">来源配置</el-menu-item>
					<el-menu-item index="release">释放管理</el-menu-item>
					<el-menu-item index="tag">标签管理</el-menu-item>
				</el-menu-item-group>
			</el-submenu>
			<el-submenu index="clue">
				<template v-slot:title="title">
					<i class="el-icon-data-line"></i>
					<span>线索管理</span>
				</template>
				<el-menu-item-group>
					<el-menu-item index="clue">线索管理</el-menu-item>
					<el-menu-item index="assignLeads">线索回收分配</el-menu-item>
				</el-menu-item-group>
			</el-submenu>
			<el-submenu index="company">
				<template v-slot:title="title">
					<i class="el-icon-lx-cascades"></i>
					<span>企业管理</span>
				</template>
				<el-menu-item-group>
					<el-menu-item index="company">企业管理</el-menu-item>
					<el-menu-item index="department">部门管理</el-menu-item>
					<el-menu-item index="user">员工管理</el-menu-item>
				</el-menu-item-group>
			</el-submenu>

			<el-submenu index="record">
				<template v-slot:title="title">
					<i class="el-icon-phone-outline"></i>
					<span>话务管理</span>
				</template>
				<el-menu-item-group><el-menu-item index="record">话务管理</el-menu-item></el-menu-item-group>
			</el-submenu>
			<el-submenu index="report">
				<template v-slot:title="title">
					<i class="el-icon-document-copy"></i>
					<span>统计管理</span>
				</template>
				<el-menu-item-group><el-menu-item index="report">员工统计报表</el-menu-item></el-menu-item-group>
			</el-submenu>

			<el-menu-item index="blackList">
				<i class="el-icon-s-release"></i>
				<span>黑名单</span>
			</el-menu-item>
			<el-menu-item index="operationManual">
				<i class="el-icon-notebook-1"></i>
				<span>使用手册</span>
			</el-menu-item>
			<div class="login_info" v-if="collapse == false">
				<div>最后登录时间:{{ lastLoginAt }}</div>
			</div>
		</el-menu>
		<el-menu class="sidebar-el-menu" :default-active="onRoutes" :collapse="collapse" background-color="#ffffff"
			text-color="#000" active-text-color="#ffffff" unique-opened router v-else-if="role == 'normal'">
			<el-menu-item index="dashboard">
				<i class="el-icon-lx-home"></i>
				<span>系统首页</span>
			</el-menu-item>
			<el-menu-item index="customer">
				<i class="el-icon-user"></i>
				<span>CRM管理</span>
				<el-menu-item-group>
					<el-menu-item index="customer">CRM管理</el-menu-item>
					<el-menu-item index="tag">标签管理</el-menu-item>
				</el-menu-item-group>
			</el-menu-item>
			<el-menu-item index="clue">
				<i class="el-icon-data-line"></i>
				<span>线索管理</span>
			</el-menu-item>
			<el-menu-item index="record">
				<i class="el-icon-phone-outline"></i>
				<span>话务管理</span>
			</el-menu-item>
			<el-menu-item index="blackList">
				<i class="el-icon-s-release"></i>
				<span>黑名单</span>
			</el-menu-item>
			<el-menu-item index="operationManual">
				<i class="el-icon-notebook-1"></i>
				<span>使用手册</span>
			</el-menu-item>
			<div class="login_info" v-if="collapse == false">
				<div>最后登录时间:{{ lastLoginAt }}</div>
			</div>
		</el-menu>

		<el-menu class="sidebar-el-menu" :default-active="onRoutes" :collapse="collapse" background-color="#ffffff"
			text-color="#000" active-text-color="#ffffff" unique-opened router v-else-if="role == 'department'">
			<el-menu-item index="dashboard">
				<i class="el-icon-lx-home"></i>
				<span>系统首页</span>
			</el-menu-item>

			<el-menu-item index="customer">
				<i class="el-icon-user"></i>
				<span>CRM管理</span>
			</el-menu-item>
			<el-submenu index="clue">
				<template v-slot:title="title">
					<i class="el-icon-data-line"></i>
					<span>线索管理</span>
				</template>
				<el-menu-item-group>
					<el-menu-item index="clue">线索管理</el-menu-item>
					<el-menu-item index="assignLeads">线索回收分配</el-menu-item>
				</el-menu-item-group>
			</el-submenu>
			<el-submenu index="department">
				<template v-slot:title="title">
					<i class="el-icon-lx-cascades"></i>
					<span>企业管理</span>
				</template>
				<el-menu-item-group>
					<el-menu-item index="department">部门管理</el-menu-item>
					<el-menu-item index="user">员工管理</el-menu-item>
				</el-menu-item-group>
			</el-submenu>
			<el-menu-item index="record">
				<i class="el-icon-phone-outline"></i>
				<span>话务管理</span>
			</el-menu-item>
			<el-submenu index="report">
				<template v-slot:title="title">
					<i class="el-icon-document-copy"></i>
					<span>统计管理</span>
				</template>
				<el-menu-item-group><el-menu-item index="report">员工统计报表</el-menu-item></el-menu-item-group>
			</el-submenu>
			<el-menu-item index="blackList">
				<i class="el-icon-s-release"></i>
				<span>黑名单</span>
			</el-menu-item>
			<el-menu-item index="operationManual">
				<i class="el-icon-notebook-1"></i>
				<span>使用手册</span>
			</el-menu-item>
			<div class="login_info" v-if="collapse == false">
				<div>最后登录时间:{{ lastLoginAt }}</div>
			</div>
		</el-menu>
	</div>
</template>

<script>
	// import bus from "../common/bus";
	export default {
		data() {
			return {
				items: [{
						icon: 'el-icon-lx-home',
						index: 'dashboard',
						title: '系统首页',
						role: ['admin', 'agent', 'company', 'normal', 'oem'],
						visiable: true,
						subs: [{
							index: 'dashboard',
							title: '系统首页',
							role: ['admin', 'agent', 'company', 'normal', 'oem'],
							visiable: true
						}]
					},
					{
						icon: 'el-icon-user',
						index: 'customer',
						title: 'CRM管理',
						role: ['admin', 'agent', 'company', 'normal', 'oem'],
						visiable: true,
						subs: [{
							index: 'customer',
							title: 'CRM管理',
							role: ['admin', 'agent', 'company', 'normal', 'oem'],
							visiable: true
						}]
					},
					{
						icon: 'el-icon-data-line',
						index: 'clue',
						title: '线索管理',
						role: ['admin', 'agent', 'company', 'normal', 'oem'],
						visiable: true,
						subs: [{
							index: 'clue',
							title: '线索管理',
							role: ['admin', 'agent', 'company', 'normal', 'oem'],
							visiable: true
						}]
					},
					{
						icon: 'el-icon-printer',
						index: 'assignLeads',
						title: '线索回收分配',
						visiable: true,
						role: ['admin', 'agent', 'company'],
						subs: [{
							index: 'assignLeads',
							title: '线索回收分配',
							role: ['admin', 'agent'],
							visiable: true
						}]
					},
					{
						icon: 'el-icon-lx-cascades',
						index: '6',
						title: '企业管理',
						visiable: true,
						role: ['admin', 'agent', 'company', 'oem'],
						subs: [{
								index: 'company',
								title: '企业管理',
								role: ['admin', 'agent', 'company', 'oem'],
								visiable: true
							},
							{
								index: 'department',
								title: '部门管理',
								role: ['admin', 'agent', 'company', 'oem'],
								visiable: true
							},
							{
								index: 'user',
								title: '员工管理',
								role: ['admin', 'agent', 'company', 'oem'],
								visiable: true
							},
							{
								index: 'tag',
								title: '标签管理',
								role: ['admin', 'agent', 'company', 'oem'],
								visiable: true
							}
						]
					},
					{
						icon: 'el-icon-phone-outline',
						index: 'record',
						title: '话务管理',
						visiable: true,
						role: ['admin', 'agent', 'company', 'normal', 'oem'],
						subs: [{
								index: 'record',
								title: '通话记录',
								role: ['admin', 'agent', 'company', 'normal', 'oem'],
								visiable: true
							},
							{
								index: 'tag',
								title: '标签管理',
								role: ['admin', 'agent', 'company', 'normal', 'oem'],
								visiable: true
							}
						]
					},
					{
						icon: 'el-icon-document-copy',
						index: '7',
						title: '统计管理',
						visiable: true,
						role: ['admin', 'agent', 'company', 'oem'],
						subs: [{
								index: 'report',
								title: '员工统计报表',
								role: ['admin', 'agent', 'company', 'oem'],
								visiable: true
							},
							{
								index: 'companyStatistics',
								title: '公司统计报表',
								role: ['admin', 'agent', 'company', 'oem'],
								visiable: true
							}
						]
					},

					{
						icon: 'el-icon-s-release',
						index: 'blackList',
						title: '黑名单',
						visiable: true,
						role: ['admin', 'agent', 'company', 'normal', 'oem'],
						subs: [{
							index: 'blackList',
							title: '黑名单',
							role: ['admin', 'agent', 'company', 'normal', 'oem'],
							visiable: true
						}]
					},
					{
						icon: 'el-icon-warning-outline',
						index: 'afterSales',
						title: '售后信息',
						visiable: true,
						role: ['admin', 'agent', 'oem'],
						subs: [{
							index: 'afterSales',
							title: '售后信息',
							role: ['admin', 'agent', 'oem'],
							visiable: true
						}]
					},

					{
						icon: 'el-icon-chat-dot-round',
						index: 'termOfValidity',
						title: '有效期管理',
						visiable: true,
						role: ['admin'],
						subs: [{
							index: 'termOfValidity',
							title: '有效期管理',
							role: ['admin'],
							visiable: true
						}]
					},
					{
						icon: 'el-icon-set-up',
						index: '12',
						title: '管理员权限',
						visiable: true,
						role: ['admin', 'oem'],
						subs: [{
								index: 'operationLog',
								title: '员工操作日志',
								role: ['admin', 'oem'],
								visiable: true
							},
							{
								index: 'agent',
								title: '代理商管理',
								role: ['admin', 'oem'],
								visiable: true
							},
							{
								index: 'agentSerial',
								title: '代理商分配记录',
								role: ['admin', 'oem'],
								visiable: true
							},
							{
								index: 'seating',
								title: '坐席数据',
								role: ['admin', 'oem'],
								visiable: true
							}
						]
					},

					{
						icon: 'el-icon-s-tools',
						index: '9',
						title: '系统设置',
						visiable: true,
						role: ['admin', 'agent', 'oem'],
						subs: [{
								index: 'cardType',
								title: '品牌管理',
								role: ['admin', 'agent', 'oem'],
								visiable: true
							},
							{
								index: 'blackListDatabase',
								title: '黑名单库',
								role: ['admin', 'agent', 'oem'],
								visiable: true
							},

							{
								index: 'role',
								title: '角色管理',
								role: ['admin', 'agent', 'oem'],
								visiable: true
							},
							{
								index: 'userSerial',
								title: '员工分配记录',
								role: ['admin', 'agent', 'oem'],
								visiable: true
							}
						]
					},
					{
						icon: 'el-icon-chat-dot-round',
						index: '71',
						title: '公告管理',
						visiable: true,
						role: ['admin', 'oem'],
						subs: [{
								index: 'notice',
								title: '公告列表',
								role: ['admin', 'oem'],
								visiable: true
							},
							{
								index: 'addNotice',
								title: '发布公告',
								role: ['admin', 'oem'],
								visiable: true
							},
							{
								index: 'swipes',
								title: '轮播图列表',
								role: ['admin', 'oem'],
								visiable: true
							},
							{
								index: 'OEMswiper',
								title: '登录轮播图',
								role: ['admin', 'oem'],
								visiable: true
							}
						]
					},
					{
						icon: 'el-icon-notebook-1',
						index: 'operationManual',
						title: '使用手册',
						role: ['admin', 'agent', 'company', 'normal', 'oem'],
						visiable: true,
						subs: [{
							index: 'operationManual',
							title: '使用手册',
							role: ['admin', 'agent', 'company', 'normal', 'oem'],
							visiable: true
						}]
					}
				],
				lastLoginIp: '',
				lastLoginAt: '',
				roleName: '',
				role: '',
				interior: false
			};
		},
		computed: {
			onRoutes() {
				return this.$route.path.replace('/', '');
			},
			collapse() {
				return this.$store.state.collapse;
			}
		},
		created() {
			this.lastLoginIp = localStorage.getItem('lastLoginIp');
			this.lastLoginAt = localStorage.getItem('lastLoginAt');
			let role = localStorage.getItem('ms_role');
			this.role = localStorage.getItem('ms_role');
			this.interior = localStorage.getItem('interior');
		},
		methods: {
			// 侧边栏折叠
			collapseChage() {
				this.$store.commit('hadndleCollapse', !this.collapse);
			}
		}
	};
</script>
<style scoped>
	.collapse-btn {
		float: left;
		padding: 0 21px;
		cursor: pointer;
		line-height: 70px;
	}

	.logo-img .logo {
		float: left;
		height: 100%;
		width: 216px;
		display: flex;
		flex-direction: row;
		align-items: center;
		color: #6c6cf9;
	}

	.logo-img .logo .img {
		width: 42px;
		margin: 0 15px 0 20px;
	}

	.logo-img .logo .text {
		width: 94px;
	}

	.logo-img {
		box-sizing: border-box;
		width: 216px;
		height: 70px;

		font-size: 22px;
		margin-bottom: 20px;
		color: #666;
	}

	.sidebar {
		display: block;
		position: absolute;
		width: 216px;
		left: 0;
		top: 0;
		bottom: 0;
		overflow-y: scroll;
	}

	.sidebar::-webkit-scrollbar {
		width: 0;
	}

	.sidebar-el-menu:not(.el-menu--collapse) {
		width: 216px;
	}

	.el-menu--collapse .el-menu-item i {
		position: relative;
		left: -38px;
	}

	.sidebar>ul {
		height: calc(100%-70px);
	}

	.login_info {
		display: block;
		position: fixed;
		left: 10px;
		bottom: 12px;

		color: #000;
	}

	.login_info div {
		font-size: 12px;
	}
</style>
<style>
	.el-menu--collapse * {
		text-align: center !important;
	}

	.el-menu-item .el-menu-item-group__title,
	.el-submenu .el-menu-item-group__title {
		padding: 0;
	}

	.el-menu .el-menu-item,
	.el-menu .el-submenu {
		margin: 0 auto !important;
		font-size: 14px !important;
	}

	.el-menu .el-menu-item.is-active {
		border-radius: 4px;
		color: #fff;
		background-color: #4f7afd !important;
	}

	.el-menu .el-menu-item:hover {
		color: #fff !important;
		background-color: #4f7afd !important;
	}

	.el-submenu.is-opened .el-submenu__title {
		color: #4f7afd !important;
	}

	.el-submenu .el-submenu__title:hover {
		background-color: #fff !important;

		color: #4f7afd !important;
	}

	.el-submenu .el-submenu__title:hover i {
		color: #4f7afd !important;
	}

	.el-submenu.is-opened .el-submenu__title i {
		color: #4f7afd !important;
	}

	.el-submenu.is-opened .el-menu .el-menu-item:hover {
		border-radius: 4px !important;
		color: #fff !important;
		background-color: #4f7afd !important;
	}

	.el-menu .el-menu-item:hover i {
		color: #fff;
	}

	.el-submenu__title,
	.el-menu-item,
	.el-submenu .el-menu-item {
		height: 46px !important;
		line-height: 46px !important;
	}

	.el-submenu__title,
	.el-submenu .el-menu-item {
		padding-left: 50px !important;
	}

	.el-menu-item {
		border-radius: 4px !important;
		width: 93.5% !important;
		min-width: 150px !important;
		background-color: red;
	}

	.el-submenu__title {
		padding-left: 27px !important;
	}

	.el-submenu__title span {
		font-size: 14px !important;
	}

	.el-menu-item span {
		font-size: 14px !important;
	}
</style>