<template>
	<div>
		<div class="container">
			<div class="handle-box" v-if="btnShow">
				<!--  v-if="tableData.length == 0" -->
				<el-button size="small" type="primary" icon="el-icon-add  el-icon--right" class="handle-add"
					@click="showForm">添加售后</el-button>
			</div>
			<el-table :data="tableData" border class="table sale-table" ref="multipleTable"
				header-cell-class-name="table-header" :row-style="{ height: '20px' }"
				:header-cell-style="{ 'text-align': 'center' }" :cell-style="{ padding: '6px' }"
				:row-class-name="tableRowClassName">
				<el-table-column prop="saleName" label="销售姓名" align="center"></el-table-column>
				<el-table-column prop="salePhone" label="销售电话" align="center"></el-table-column>
				<el-table-column prop="saleWechat" label="销售微信" align="center">
					<template v-slot="scope">
						<img :src="scope.row.saleWechat" width="40" height="40" class="head_pic" />
					</template>
				</el-table-column>
				<el-table-column prop="serviceName" label="客服姓名" align="center"></el-table-column>
				<el-table-column prop="servicePhone" label="客服电话" align="center"></el-table-column>
				<el-table-column prop="serviceWechat" label="客服微信" align="center">
					<template v-slot="scope">
						<img :src="scope.row.serviceWechat" width="40" height="40" class="head_pic" />
					</template>
				</el-table-column>
				<el-table-column label="操作" width="180" align="center">
					<template #default="scope">
						<el-button size="small" type="text" icon="el-icon-edit"
							@click="handleEdit(scope.$index, scope.row.id)">编辑</el-button>
						<el-button size="small" type="text" icon="el-icon-delete" class="red"
							@click="handleDelete(scope.$index, scope.row.id)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>

		<el-dialog title="销售信息编辑" v-model="addVisible" width="500px" @close="closeDialog('form')"
			:close-on-click-modal="false">
			<el-form :model="form" ref="form" label-width="100px">
				<el-form-item label="销售姓名" prop="saleName"><el-input size="small"
						v-model="form.saleName"></el-input></el-form-item>
				<el-form-item label="销售电话" prop="salePhone"><el-input size="small"
						v-model="form.salePhone"></el-input></el-form-item>
				<el-form-item label="销售微信" prop="saleWechat">
					<!-- <el-upload
						class="avatar-uploader"
						:action="action2"
						:show-file-list="false"
						accept="image/png,image/jpg,image/jpeg"
						:on-success="handleAvatarSuccess1"
						:before-upload="beforeAvatarUpload"
						:headers="headers"
					>
						<img v-if="headerUrl" :src="headerUrl" class="avatar" />
						<i v-else class="el-icon-plus avatar-uploader-icon" style="font-size: 66px;margin:55px auto;"></i>
					</el-upload> -->
					<el-upload :disabled="canRemoveCompany" :action="action2" limit="1" list-type="picture-card"
						:file-list="fileList1" :auto-upload="false" ref="upload1"
						accept="image/png,image/jpg,image/jpeg" :before-upload="beforeAvatarUpload"
						:on-change="getSaleWechat" :data="token1" :http-request="httprequest"
						:class="{ hide: hideUpload1 }" :before-remove="beformRemove1" :on-remove="handleRemove1">
						<el-button size="small" type="primary">点击上传</el-button>
					</el-upload>
					<el-dialog v-model="addVisible1"><img :src="dialogImageUrl1" style="width: 100%;" /></el-dialog>
				</el-form-item>
				<el-form-item label="客服姓名" prop="serviceName"><el-input size="small"
						v-model="form.serviceName"></el-input></el-form-item>
				<el-form-item label="客服电话" prop="servicePhone"><el-input size="small"
						v-model="form.servicePhone"></el-input></el-form-item>
				<el-form-item label="客服微信" prop="serviceWechat">
					<!-- <el-upload
						class="avatar-uploader"
						:action="action"
						:show-file-list="false"
						accept="image/png,image/jpg,image/jpeg"
						:on-success="handleAvatarSuccess2"
						:before-upload="beforeAvatarUpload"
						:headers="headers"
					>
						<img v-if="headerUrl2" :src="headerUrl2" class="avatar" />
						<i v-else class="el-icon-plus avatar-uploader-icon" style="font-size: 66px;margin:55px auto;"></i>
					</el-upload> -->
					<el-upload :disabled="canRemoveCompany" :action="action" limit="1" list-type="picture-card"
						:file-list="fileList2" :auto-upload="false" ref="upload2"
						accept="image/png,image/jpg,image/jpeg" :on-change="getServiceWechat" :data="idcardFront"
						:http-request="httprequest" :class="{ hide: hideUpload2 }" :before-remove="beformRemove2"
						:on-remove="handleRemove2">
						<el-button size="small" type="primary">点击上传</el-button>
					</el-upload>
					<el-dialog v-model="addVisible2"><img :src="dialogImageUrl2" style="width: 100%;" /></el-dialog>
				</el-form-item>
			</el-form>
			<span class="dialog-footer myRight">
				<el-button size="small" @click="closeDialog('form')">取消</el-button>
				<el-button size="small" v-if="addButton" type="primary" @click="addAfterSale('form')">确定添加</el-button>
				<el-button size="small" v-if="updateButton" type="primary"
					@click="updateAfterSale('form')">确定编辑</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import { deleteAfterSale, addAfterSale, updateAfterSale, findAfterSale, getAfterSaleById,
		delAfterSales } from '../api/agentIndex.js';

	export default {
		name: 'afterSales',
		data() {
			return {
				tableData: [],
				action: '/api/afterSale/uploadServiceWechat', //上传二维码地址
				action2: '/api/afterSale/uploadSaleWechat', //上传销售二维码地址
				headers: {
					Authorization: localStorage.getItem('token')
				},
				addVisible: false,
				addButton: false,
				updateButton: false,
				editVisible: false,

				btnShow: false,
				fileList1: [],
				fileList2: [],
				dialogImageUrl1: null,
				dialogImageUrl2: null,
				addVisible1: false,
				addVisible2: false,
				hideUpload1: false,
				hideUpload2: false,
				canRemoveCompany: false,
				form: {
					saleName: '',
					salePhone: '',
					saleWechat: '',
					lastSaleWechat: '',
					serviceName: '',
					servicePhone: '',
					serviceWechat: '',
					lastServiceWechat: ''
				},
				token: { Authorization: localStorage.getItem('token') }
			};
		},
		created() {
			this.findAfterSales();
		},
		methods: {
			tableRowClassName({ rowIndex }) {
				if ((rowIndex + 1) % 2 === 0) {
					return 'oddRow';
				}
				return 'evenRow';
			},
			findAfterSales() {
				let data = {
					currentUserId: localStorage.getItem('user')
				};
				findAfterSale(data).then(res => {
					if (res.data) {
						this.tableData = res.data;
					} else {
						this.btnShow = true;
					}
				});
			},
			showForm() {
				this.addVisible = true;
				this.addButton = true;
				this.updateButton = false;
			},
			addAfterSale(formName) {
				this.$refs[formName].validate(valid => {
					if (valid) {
						this.loading = true;
						this.form.currentUserId = localStorage.getItem('user');
						addAfterSale(this.form).then(res => {
							if (res.code === 200) {
								this.$message.success('添加成功');
								this.findAfterSales();
							}
							this.addVisible = false;
						});
					}
				});
			},
			// 转图片转vase64
			getBase64(file) {
				return new Promise(function(resolve, reject) {
					let reader = new FileReader();
					let imgResult = '';
					reader.readAsDataURL(file);
					reader.onload = function() {
						imgResult = reader.result;
					};
					reader.onerror = function(error) {
						reject(error);
					};
					reader.onloadend = function() {
						resolve(imgResult);
					};
				});
			},
			// 删除照片
			handleRemove1(file, fileList) {
				this.form.saleWechat = '';
				this.hideUpload1 = fileList.length >= 1;
			},
			handleRemove2(file, fileList) {
				this.form.serviceWechat = '';
				this.hideUpload2 = fileList.length >= 1;
			},
			// 上传照片后隐藏上传按钮
			beformRemove1(fileList) {
				this.hideUpload1 = fileList.length >= 1;
			},
			beformRemove2(fileList) {
				this.hideUpload2 = fileList.length >= 1;
			},
			// 销售微信
			getSaleWechat(file, fileList) {
				this.hideUpload1 = fileList.length >= 1;
				const isL2M = file.size / 1024 / 1024 < 2;

				if (isL2M) {
					this.getBase64(file.raw).then(res => {
						this.form.saleWechat = res;
					});
				} else {
					this.$message.error('上传图片大小不能超过 2MB!');
					this.$refs.upload1.clearFiles();
					this.hideUpload1 = false;
				}
				return isL2M;
			},
			getServiceWechat(file, fileList) {
				this.hideUpload2 = fileList.length >= 1;
				const isL2M = file.size / 1024 / 1024 < 2;
				if (isL2M) {
					this.getBase64(file.raw).then(res => {
						this.form.serviceWechat = res;
						const params = res.split(',');
						if (params.length > 0) {
							this.strimagebase64 = params[1];
						}
					});
				} else {
					this.$message.error('上传图片大小不能超过 2MB!');
					this.$refs.upload2.clearFiles();
					this.hideUpload2 = false;
				}
				return isL2M;
			},

			// 编辑操作
			handleEdit(index, id) {
				this.addVisible = true;
				this.idx = index;
				this.addButton = false;
				this.updateButton = true;
				getAfterSaleById(id).then(res => {
					this.form = res.data[0];
					if (res.data[0].saleWechat) {
						this.fileList1 = [{ url: res.data[0].saleWechat }];
						this.hideUpload1 = this.fileList1.length >= 1;
					}
					if (res.data[0].serviceWechat) {
						this.fileList2 = [{ url: res.data[0].serviceWechat }];
						this.hideUpload2 = this.fileList2.length >= 1;
					}
					this.headerUrl = this.form.saleWechat;
					this.headerUrl2 = this.form.serviceWechat;
				});
			},
			updateAfterSale(formName) {
				this.$refs[formName].validate(valid => {
					if (valid) {
						this.loading = true;
						this.form.currentUserId = localStorage.getItem('user');
						updateAfterSale(this.form).then(res => {
							if (res.code === 200) {
								this.$message.success('修改成功');
								this.findAfterSales();
							} else {
								this.$message.error(res.message);
							}
							this.addVisible = false;
						});
					}
				});
			},

			// 删除操作
			handleDelete(index, id) {
				// 二次确认删除
				this.$confirm('确定要删除吗？', '提示', {
						type: 'warning'
					})
					.then(() => {
						let data = {
							afterSaleId: id
						};
						deleteAfterSale(data).then(res => {
							if (res.code == 200) {
								this.$message.success('删除成功');
								this.pageTotal = this.pageTotal - 1;
								this.tableData.splice(index, 1);
							} else {
								this.$message.error(res.message);
							}
						});
					})
					.catch(() => {});
			},

			resetForm(formName) {
				this.$refs[formName].resetFields();
			},
			closeDialog(formName) {
				this.addVisible = false;
				(this.form = {
					saleName: '',
					salePhone: '',
					saleWechat: '',
					lastSaleWechat: '',
					serviceName: '',
					servicePhone: '',
					serviceWechat: '',
					lastServiceWechat: ''
				}),
				this.resetForm(formName);
			}
		}
	};
</script>

<style scoped>
	.handle-box {
		margin-bottom: 20px;
	}

	/* a:hover {
	background: #66b1ff;
} */
	.handle-select {
		width: 240px;
	}

	.table {
		width: 100%;
		font-size: 14px;
	}

	.red {
		color: #f56c6c;
	}

	.mr10 {
		margin-right: 10px;
	}

	.mr100 {
		position: absolute;
		right: 100px;
	}

	.myRight {
		position: absolute;
		right: 10px;
		bottom: 10px;
	}

	.handle-input {
		width: 200px;
		display: inline-block;
	}
</style>
<style>
	.sale-table .avatar-uploader .el-upload--text {
		font-size: 66px !important;
	}

	.hide .el-upload--picture-card {
		display: none;
	}
</style>