import request from '../utils/request';
import qs from "qs";

export const list = param => {
	return request.post('/api/company/list', qs.stringify(param))
};

export const addCompany = param => {
	return request.post('/api/company/add', qs.stringify(param))
};
// 企业类型
export const fetchCompanyType = res => {
	return request.get('/api/company/fetchCompanyType')
};
export const updateCompany = param => {
	return request.post('/api/company/update', qs.stringify(param))
};

export const delCompany = data => {
	return request.get('/api/company/delete?userId=' + data.userId + '&&id=' + data.id)
};


export const fetchCompanyById = companyId => {
	return request.get('/api/company/fetchCompanyById?companyId=' + companyId)
};

export const fetchCompanyByAgentId = data => {
	return request.get('/api/company/fetchCompanyByAgentId?agentId=' + data.agentId)
};

// eslint-disable-next-line no-unused-vars
export const fetchDefaultCompanies = data => {
	return request.get('/api/company/fetchDefaultCompanies?currentUserId=' + data.currentUserId)
};

export const getQiNiuYunToken = agentId => {
	return request.get('/api/company/uploadToken')
};
//号码池列表
export const numberList = param => {
	return request.post('/api/numberPool/list', qs.stringify(param))
};
//号码池导入
export const numberPoolUpload = param => {
	return request.post('/api/numberPool/upload', qs.stringify(param))
};
//号码池删除
export const numberPoolDelete = param => {
	return request.post('/api/numberPool/delete', qs.stringify(param))
};
//查询省份
export const getProvince = param => {
	return request.post('/api/numberPool/getProvince', qs.stringify(param))
};
//查询市
export const getCity = param => {
	return request.post('/api/numberPool/getCity', qs.stringify(param))
};
//编辑
export const numberPoolEdit = param => {
	return request.post('/api/numberPool/update', qs.stringify(param))
};
//添加
export const addReportNumber = param => {
	return request.post('/api/blackList/addReportNumber', qs.stringify(param))
};

//文件删除
export const numberPooldeleteFile = param => {
	return request.post('/api/numberPool/deleteUpload', qs.stringify(param))
};


//单个或者批量删除
export const snailsDeletes = param => {
	return request.post('/api/numberPool/snailsDelete', qs.stringify(param))
};

//单个或者批量删除
export const getSnailsList = param => {
	return request.post('/api/numberPool/snailsList', qs.stringify(param))
};
//修改是否合作
export const updateEnable = param => {
	return request.post('/api/company/updateEnable', qs.stringify(param))
};