import request from '../utils/request';
import qs from "qs";

export const fetchAgent = currentUserId => {
	return request.get('/api/agent/fetchAgent?currentUserId=' + currentUserId)
};
export const findAfterSale = data => {
	return request.get('/api/afterSale/findAfterSale?currentUserId=' + data.currentUserId)
};
export const getAfterSaleById = id => {
	return request.get('/api/afterSale/getAfterSaleById?id=' + id)
};
export const findAfterSaleByRole = data => {
	return request.get('/api/afterSale/findAfterSaleByRole?currentUserId=' + data.currentUserId)
};

export const updateAfterSale = param => {
	return request.post('/api/afterSale/updateAfterSale', qs.stringify(param))
};
export const addAfterSale = param => {
	return request.post('/api/afterSale/addAfterSale', qs.stringify(param))
};
export const deleteAfterSale = param => {
	return request.post('/api/afterSale/deleteAfterSale', qs.stringify(param))
};

export const list = param => {
	return request.post('/api/agent/list', qs.stringify(param))
};

export const addAgent = param => {
	return request.post('/api/agent/add', qs.stringify(param))
};

//获取代理账号列表
export const agentAccount = id => {
	return request.get('/api/agent/account?id=' + id)
};
//修改代理账号状态
export const stopAccount = param => {
	return request.post('/api/agent/stopAccount', qs.stringify(param))
};
//编辑 代理账号
export const updateAccount = param => {
	return request.post('/api/agent/updateAccount', qs.stringify(param))
};
//添加 代理账号
export const addAccount = param => {
	return request.post('/api/agent/addAccount', qs.stringify(param))
};
//删除 代理账号
export const deleteAccount = param => {
	return request.post('/api/agent/deleteAccount', qs.stringify(param))
};
export const updateAgent = param => {
	return request.post('/api/agent/update', qs.stringify(param))
};


export const delAgent = id => {
	return request.get('/api/agent/delete?id=' + id)
};

export const fetchAgentById = agentId => {
	return request.get('/api/agent/fetchAgentById?agentId=' + agentId)
};

// eslint-disable-next-line no-unused-vars
export const fetchInitAgent = param => {
	return request.post('/api/agent/fetchInitAgent', qs.stringify(param))
};

//添加黑名单库
export const addCompany = param => {
	return request.post('/api/blackList/addCompany', qs.stringify(param))
};
//查询黑名单库列表
export const getCompany = param => {
	return request.get('/api/blackList/getCompany?companyName=' + param.companyName + '&&userId=' + param.userId +
		'&&pageIndex=' + param.pageIndex + '&&pageSize=' + param.pageSize, )
};
//删除和批量删除
export const deleteCompany = param => {
	return request.post('/api/blackList/deleteCompany', qs.stringify(param))
};